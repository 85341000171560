<script setup lang="ts">
import { computed } from "vue"

interface Props {
    checked?: [] | boolean
    value?: null | string
}

const props = defineProps({
  "checked": { type: [Array, Boolean], default: false },
  "value": { default: null }
})

const emit = defineEmits(["update:checked"])

const proxyChecked = computed({
    get() {
        return props.checked
    },

    set(val: boolean) {
        emit("update:checked", val)
    },
})
</script>

<template>
    <input
        v-model="proxyChecked"
        type="checkbox"
        :value="value"
        class="rounded border-gray-300 text-indigo-600 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50" />
</template>
